import ButtonUnstyled, { ButtonUnstyledProps } from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";

const colors = {
  primary: {
    background: "#3d69e1",
    text: "white",
  },
  secondary: {
    background: "rgba(23, 26, 32, 0.8)",
    text: "white",
  },
  transparent: {
    background: "rgba(244, 244, 244, 0.65)",
    text: "#393c41",
  },
  dark: {
    background: "#171a20",
    text: "white",
  },
};

const PillButton = (
  props: ButtonUnstyledProps & { selected?: boolean }
): JSX.Element => {
  const { color, disabled, selected } = props;

  const parseColor = (color: string | undefined) => {
    switch (color) {
      case "secondary":
        return colors.secondary;
      case "transparent":
        return colors.transparent;
      case "dark":
        return colors.dark;
      default:
        return colors.primary;
    }
  };

  const buttonColor = parseColor(color);

  const CustomButtonRoot = styled("button")`
    blur: 9px;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    padding: 13px;
    background-color: ${disabled ? colors.transparent : buttonColor.background};
    text-transform: uppercase;
    border: ${selected ? "1px solid black" : "none"};
    border-radius: 100px;
    color: ${disabled ? colors.transparent : buttonColor.text};
    transition: all 150ms ease;
    cursor: pointer;
  `;
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
};

export default PillButton;
