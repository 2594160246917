import { Box, Container, Grid, Typography } from "@mui/material";
import { PageSection } from "interfaces/api_interfaces";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useLocalization } from "providers/LocalizationProvider";
import Footer from "../common/Footer";
import CoverVideo, { FitType } from "@/components/common/CoverVideo";
import PillButton from "../buttons/PillButton";
import { getPageSectionActionUrlFromLocale } from "../../utils/helpers";

interface Props {
  template: PageSection;
  showFooter?: boolean;
}

const BottomTextTemplate = ({
  template,
  showFooter = false,
}: Props): JSX.Element => {
  const { toLocalizedString } = useLocalization();
  const router = useRouter();
  const { name, alt, description, image, actions, vimeo_id } = template;

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box flexGrow={1}>
        <Grid container position="absolute" height={showFooter ? 0.92 : 1}>
          <Grid
            item
            xs={12}
            minHeight={{ xs: 0.2, md: 0.5 }}
            position="relative"
          >
            {vimeo_id == null ? (
              <Image
                src={image}
                alt={toLocalizedString(alt)}
                objectFit="cover"
                layout="fill"
                loading="eager"
              />
            ) : (
              <CoverVideo vimeoId={vimeo_id} fit={FitType.FIT_SCREEN_WIDTH} />
            )}
            <Box
              sx={{
                position: "absolute",
                bottom: 20,
                width: 1,
              }}
            >
              <Container
                fixed={true}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: 2,
                }}
              ></Container>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              maxHeight: { xs: 0.8, md: 0.5 },
            }}
          >
            <Container
              fixed={true}
              sx={{
                height: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 3,
              }}
            >
              <Typography variant="h2" sx={{ mb: 3 }}>
                {toLocalizedString(name)}
              </Typography>
              {toLocalizedString(description)
                .split("\n")
                .map((t, i) => (
                  <Typography
                    variant="body1"
                    key={i}
                    maxHeight={0.5}
                    sx={{ overflowY: "auto", mt: i === 0 ? 0 : 0.5 }}
                  >
                    {t}
                  </Typography>
                ))}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  mt: 3,
                  gap: 1,
                }}
              >
                {actions.map((action) => {
                  const { pk, title } = action;
                  const url = getPageSectionActionUrlFromLocale(
                    action,
                    router.locale
                  );
                  return (
                    <PillButton
                      key={pk}
                      style={{ maxWidth: "450px" }}
                      color="dark"
                      onClick={() => router.push(url)}
                    >
                      {toLocalizedString(title)}
                    </PillButton>
                  );
                })}
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
      {showFooter && (
        <Box>
          <Footer absolute={true} />
        </Box>
      )}
    </Box>
  );
};

export default BottomTextTemplate;
