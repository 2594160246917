import { Box, Container, Stack, Typography } from "@mui/material";
import { PageSectionAction } from "interfaces/api_interfaces";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useLocalization } from "providers/LocalizationProvider";
import PillButton from "../buttons/PillButton";
import Footer from "../common/Footer";
import HeroBadge from "../common/HeroBadge";
import CoverVideo, { FitType } from "../common/CoverVideo";
import { getPageSectionActionUrlFromLocale } from "../../utils/helpers";

export interface IHeroBadge {
  title: string;
  description: string;
  color?: string;
}

interface Props {
  name: string;
  alt: string;
  description?: string;
  badges?: IHeroBadge[];
  textColor?: string;
  actions?: PageSectionAction[];
  vimeo_id?: string;
  showFooter?: boolean;
  image: string;
}

const HeroTemplate = ({
  name,
  alt,
  description,
  badges,
  image,
  vimeo_id,
  showFooter = false,
  textColor = "black",
  actions,
}: Props): JSX.Element => {
  const { toLocalizedString } = useLocalization();
  const router = useRouter();

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box flexGrow={1}>
        <Box sx={{ height: 1, width: 1, position: "relative" }}>
          {vimeo_id ? (
            <Box
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <CoverVideo vimeoId={vimeo_id} fit={FitType.FIT_SCREEN_WIDTH} />
            </Box>
          ) : (
            <Image
              src={image}
              alt={alt}
              layout="fill"
              objectFit="cover"
              loading="eager"
              objectPosition="center"
            />
          )}

          {/* Container with max width */}
          <Container
            sx={{
              height: 1,
              position: "absolute",
              color: textColor,
              left: 0,
              right: 0,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ wordBreak: "break-word", mt: 18, flexGrow: 1 }}>
              <Typography variant="h1" color="inherit" sx={{ mb: 3 }}>
                {name}
              </Typography>
              {description &&
                description.split("\n").map((t, i) => (
                  <Typography
                    variant="body1"
                    key={i}
                    maxHeight={0.5}
                    color="inherit"
                    sx={{ overflowY: "auto", mt: i === 0 ? 0 : 0.5 }}
                  >
                    {t}
                  </Typography>
                ))}
            </Box>
            <Stack
              marginBottom={8}
              width={{ xs: 1, md: "600px" }}
              direction={{ xs: "column", md: "row" }}
              spacing={badges ? 10 : 2}
              sx={{ mt: 3 }}
              p={2}
            >
              {badges
                ? badges.map((badge, id) => (
                    <HeroBadge
                      key={id}
                      title={badge.title}
                      description={badge.description}
                      color={textColor}
                    />
                  ))
                : actions?.map((action, id) => {
                    const { pk, title } = action;
                    const url = getPageSectionActionUrlFromLocale(
                      action,
                      router.locale
                    );
                    if (id % 2 == 0) {
                      return (
                        <PillButton
                          key={pk}
                          onClick={() => router.push(url)}
                          color="secondary"
                        >
                          {toLocalizedString(title)}
                        </PillButton>
                      );
                    }
                    return (
                      <PillButton
                        key={pk}
                        color="transparent"
                        onClick={() => router.push(url)}
                      >
                        {toLocalizedString(title)}
                      </PillButton>
                    );
                  })}
            </Stack>
          </Container>
        </Box>
      </Box>
      {showFooter && (
        <Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
};

export default HeroTemplate;
