import { Box, Typography } from "@mui/material";
import { IHeroBadge } from "../templates/HeroTemplate";

const HeroBadge = ({
  title,
  description,
  color = "white",
}: IHeroBadge): JSX.Element => {
  return (
    <Box color={color}>
      <Typography variant="h2" color="inherit">
        {title}
      </Typography>
      <Typography variant="body1" color="inherit">
        {description}
      </Typography>
    </Box>
  );
};

export default HeroBadge;
