import { HTMLProps, useRef, useState } from "react";
import { Box, Fade } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useGlobalState } from "providers/GlobalProvider";
import { NavColor } from "./Navbar";

const SnapScrollContainer = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
  const { colors } = useGlobalState();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showScroll, setShowScroll] = useState(true);

  const onScroll = () => {
    // Check if scroll is at the top
    if (containerRef.current) {
      if (containerRef.current.scrollTop === 0) {
        setShowScroll(true);
      } else {
        if (containerRef.current.scrollTop > 60 && showScroll) {
          setShowScroll(false);
        }
      }
    }
  };

  return (
    <Box
      ref={containerRef}
      onScroll={onScroll}
      sx={{
        scrollSnapType: "y mandatory",
        overflowX: "hidden",
        overflowY: "scroll",
        height: "100%",
        width: "100%",
        position: "fixed",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "& > div": {
          scrollSnapAlign: "start",
          position: "relative",
        },
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {props.children}
      {showScroll && (
        <Fade in={showScroll} timeout={500}>
          <ExpandMore
            sx={{
              color: {
                xs: "black",
                lg: colors.scroll === NavColor.Light ? "black" : "white",
              },
              position: "fixed",
              bottom: 20,
              left: "50%",
              transform: "translateX(-50%)",
              fontSize: 40,
            }}
          />
        </Fade>
      )}
    </Box>
  );
};

export default SnapScrollContainer;
