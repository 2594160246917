enum FitType {
  FIT_SCREEN_HEIGHT,
  FIT_SCREEN_WIDTH,
}

type Props = {
  vimeoId: string
  fit: FitType
};

const CoverVideo = ({vimeoId, fit}: Props): JSX.Element => {
  return (
      <div style={{
        position: "relative",
        width: "100%",
        height: "100%",
        zIndex: -1,
      }}>
        <div style={{
          position: "absolute",
          // -1 px everywhere to avoid a white border on some browsers and screen sizes
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          zIndex: -1,
          pointerEvents: 'none',
          overflow: 'hidden',
        }}>
          <iframe src={`https://player.vimeo.com/video/${vimeoId}?background=1&controls=0&autoplay=1&byline=0&title=0&muted=1&loop=1&autopause=0`}
                  allow="autoplay; fullscreen"
                  loading="lazy"
                  title="vimeo video player"
                  frameBorder="0" allowFullScreen style={fit == FitType.FIT_SCREEN_HEIGHT ? {
            width: '100vw',
            height: '56.25vw', /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            minHeight: '100vh',
            minWidth: '177.77vh', /* Given a 16:9 aspect ratio, 16/9*100 = 177.77  */
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          } : {
            height: '100vh',
            width: '177.78vh', /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            minWidth: '100vw',
            minHeight: '56.25vw', /* Given a 16:9 aspect ratio, 16/9*100 = 177.77  */
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}/>
        </div>

      </div>
  );
};

export default CoverVideo;

export {
  FitType
}