import { NextSeo } from "next-seo";
import {
  BASE_URL,
  DEFAULT_OG_IMAGE,
  DEFAULT_OG_IMAGE_ALT,
} from "utils/seo/constants";

type SeoImage = {
  url: string;
  alt?: string;
};

interface BaseSeoProps {
  title?: string;
  description?: string;
  image?: SeoImage;
  ogUrl?: string;
}

const BaseSeo = ({
  title,
  description,
  image,
  ogUrl,
}: BaseSeoProps): JSX.Element => {
  const baseTitle = "DreamCase";
  const baseDescription =
    "DreamCase was designed to make car travel spontaneous, convenient and safe by swiftly unfolding into a double mattress for the coziest of rest.";

  const seoTitle = title ? `${title} | DreamCase` : baseTitle;
  const seoDescription = description ? description : baseDescription;

  const seoImage: SeoImage = {
    url: image?.url || DEFAULT_OG_IMAGE,
    alt: image?.alt || DEFAULT_OG_IMAGE_ALT,
  };

  return (
    <NextSeo
      title={seoTitle}
      description={seoDescription}
      openGraph={{
        title: seoTitle,
        description: seoDescription,
        type: "website",
        url: ogUrl || BASE_URL,
        images: [seoImage],
        site_name: "DreamCase",
      }}
      twitter={{
        handle: "@dreamcased",
        site: "@dreamcase",
        cardType: "summary_large_image",
      }}
    />
  );
};

export default BaseSeo;
